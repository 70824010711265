import {Component, ComponentRef, Injector, Input, OnInit, ViewContainerRef} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {ClientConfiguration, ComponentConfiguration} from '@twpub/core/utils';
import {PageCompType} from '@twpub/core/enums';
import {ConfigurableComponent, SessionObject} from '@twpub/core/models';
import {
  ClientConfigurationService,
  ComponentDeclarationService,
  NavigationService,
  SharedStateService
} from '@twpub/core/services';
import {ActivatedRoute} from '@angular/router';

@Component({
  template: ''
})

export abstract class BaseWrapperComponent<T extends ConfigurableComponent> implements OnInit {
  protected abstract type: PageCompType
  protected logger: NGXLogger
  protected clientConfigService: ClientConfigurationService
  protected sharedStateService: SharedStateService;
  protected componentConfig?: ComponentConfiguration<T>
  protected componentRef?: ComponentRef<T>
  private componentDeclarationService: ComponentDeclarationService;
  private route: ActivatedRoute;

  visible: boolean = true

  private clientConfig!: ClientConfiguration
  /**
   * Not used, but required for component to update properly.
   */
  @Input() sessionObj!: SessionObject;
  private navService: NavigationService;

  constructor(private injectorObj: Injector) {
    this.logger = injectorObj.get(NGXLogger);
    this.componentDeclarationService = injectorObj.get(ComponentDeclarationService);
    this.sharedStateService = injectorObj.get(SharedStateService);
    this.clientConfigService = injectorObj.get(ClientConfigurationService);
    this.navService = injectorObj.get(NavigationService);
    this.route = injectorObj.get(ActivatedRoute);
  }

  ngOnInit(): void {
    this.logger.debug('BaseWrapperComponent.ngOnInit:', {})
    this.route.data.subscribe({
      next: (data) => {
        this.clientConfig = data['clientConfig'];
        this.logger.debug('BaseWrapperComponent.ngOnInit:', {clientConfig: this.clientConfig});
        this.loadComponent();
        this.init();
      }
    })
  }

  protected init(): void {
    this.update();
  }

  loadComponent() {
    this.getViewContainerRef().clear()
    if (this.clientConfig) {
      this.createComponent();
    }
  }

  protected createComponent(): T {
    this.componentConfig = this.getConfiguration(this.type);
    this.componentRef = this.getViewContainerRef().createComponent<T>(this.componentConfig.component);
    return this.componentRef.instance;
  }

  protected getConfiguration(compType: PageCompType): ComponentConfiguration<any> {
    const config = this.clientConfig.getConfigurationSafe(compType);
    return config || this.componentDeclarationService.createComponentConfigurationForType(compType);
  }

  protected abstract getViewContainerRef(): ViewContainerRef

  update(updateFn?: (comp: T) => void, updateArgs?: any) {
    if (this.componentRef) {
      const comp = this.componentRef.instance;
      comp.logger = this.logger;
      comp.sessionObj = this.sharedStateService._sessionObj;
      comp.config = this.componentConfig;
      comp.pageCompType = this.type;
      if (updateFn) {
        updateFn(comp);
      }
      comp.doUpdate?.(updateArgs);
    }
  }
}
