import {Injectable, Injector} from '@angular/core';
import {catchError, Observable, shareReplay} from 'rxjs';
import {BaseService} from './base.service';
import {Dictionary, Language, Section} from '../models';
import {DictionaryStatistics} from '@twpub/core/models/dictionary-statistics';
import {SynchronizationStatistics} from '@twpub/core/models/synchronization-statistics';

@Injectable({
  providedIn: 'root'
})
export class DictionaryService extends BaseService {
  dictCache?: Observable<Dictionary[]>;

  constructor(private injector: Injector) {
    super(injector);
  }

  /**
   * Returns the language name for specified language code in the language array.
   * @param langs Language array
   * @param langCode Language code.
   */
  public getLanguageName(langs: Language[], langCode?: string): string {
    return langs.find((l) => l.code === langCode)?.name || '';
  }

  public getLanguages(dictId: number): Observable<Language[]> {
    return this.http.get<Language[]>(this.url(`/dictionaries/${dictId}/languages`))
      .pipe(shareReplay(1));
  }

  public getAllLanguages(dictId: number): Observable<Language[]> {
    return this.http.get<Language[]>(this.url(`/dictionaries/${dictId}/languages?all=true`))
      .pipe(shareReplay(1));
  }

  /**
   * Returns the dictionary definition.
   * @param dictId Dictionary id.
   */
  public getDictionary(dictId: number): Observable<Dictionary> {
    return this.http.get<Dictionary>(this.url(`/dictionaries/${dictId}`));
  }

  /**
   * Returns all dictionaries.
   */
  public getDictionaries(onyAvailable: boolean = false): Observable<Dictionary[]> {
    let obs = this.dictCache;
    if (!obs) {
      let uri = '/dictionaries';
      if (onyAvailable) {
        uri += '?only-available=true';
      }
      obs = this.http.get<Dictionary[]>(this.url(uri)).pipe(shareReplay(1))
    }
    return obs;
  }

  /**
   * Returns statistics for all dictionaries.
   */
  public getDictionaryStatistics(): Observable<DictionaryStatistics[]> {
    return this.http.get<DictionaryStatistics[]>(this.url('/dictionaries/statistics'))
      .pipe(shareReplay(1))
  }

  /**
   * Performs reindex of entire dictionary.
   */
  public reindexDictionary(dictId: number): Observable<any> {
    return this.http.post(this.url(`/dictionaries/${dictId}/reindex`), {});
  }

  public getSections(dictId: number): Observable<Section[]> {
    return this.http.get<Section[]>(this.url(`/dictionaries/${dictId}/sections`))
      .pipe(shareReplay(1));
  }

  public getAllSections(dictId: number): Observable<Section[]> {
    return this.http.get<Section[]>(this.url(`/dictionaries/${dictId}/sections?all=true`))
      .pipe(shareReplay(1));
  }

  updateSection(section: Section): Observable<any> {
    return this.http.put(this.url(`/dictionaries/${section.dictionaryId}/sections`), section);
  }

  /**
   * Refreshes dictionary and fetches the latest term data changes.
   */
  public refreshDictionary(dictId: number): Observable<SynchronizationStatistics> {
    return this.http.get<SynchronizationStatistics>(this.url(`/dictionaries/${dictId}/refresh`));
  }

  /**
   * Refreshes list of dictionaries.
   */
  public refreshDictionaries(): Observable<any> {
    return this.http.post(this.url('/dictionaries/refresh'), {});
  }

  /**
   * Deletes index for entire dictionary.
   */
  public deleteIndex(dictId: number): Observable<any> {
    return this.http.post(this.url(`/dictionaries/${dictId}/delete-index`), {});
  }

  updateLanguage(language: Language) {
    return this.http.put(this.url(`/dictionaries/${language.dictionaryId}/languages`), language);
  }
}
