import {EnvironmentInterface} from './environment-interface';
import {NgxLoggerLevel} from 'ngx-logger';

export const environment: EnvironmentInterface = {
  production: true,
  appVersion: require('../../package.json').version,
  buildNumber: '41',
  serverUrl: '/twpub-server/',
  logLevel: NgxLoggerLevel.WARN,
  keycloak: {
    url: '/keycloak/',
    realm: 'twpub',
    clientId: 'twpub-frontend'
  }
};
